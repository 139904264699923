.dark {
    background-image: linear-gradient(310deg,#344767,#344767) !important;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse {
    height: calc(100vh - 200px);
}
.btn-primary {
    background-image: linear-gradient(310deg,#141727,#3a416f);
}
.modal-header,
.modal-footer {
    border: 0 !important;
}
.modal-header {
    padding-bottom: 0;
}
.pagination {
    padding: 1rem;
}
.pagination nav > div.justify-between {
    display: none !important;
}
.pagination svg {
    max-width: 20px;
    width: 100% !important;
}
.pagination div span.rounded-md {
    display: flex;
    grid-gap: 0.5rem;
    align-items: center;
    box-shadow: none !important;
}
.pagination div span.rounded-md * {
    max-height: 30px;
    padding: 0 0px !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    border-radius: 50%;
    border-color: #eee !important;
}
.pagination div span.rounded-md a {
    background-color: #eee !important;
}
.products .card {
    height: 100%;
}
.btn-link {
    color: #232843 !important;
}
.products h2 {
    font-size: 1.6rem;
}
.products h1 {
    font-size: 1.875rem;
    line-height: 1.375;
}
.products p, .products ul {
    font-size: 14px;
}
.amount {
    font-weight: 700;
}
/* input.amount {
    border: 0;
    display: inline;
    width: auto;
} */
.profile-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mini-column {
    width: 20px;
    padding-right: 0;
}
.form-sm,
.table-actions {
    display: flex;
    max-height: 40px;
    height: 100%;
}
.form-sm .btn {
    margin-bottom: 0 !important;
}
.select-sm {
    display: flex;
    width: 124px;
    padding: 0 10px;
    height: 32px;
}
.table-actions.spec *{
    height: 32px;
}
.table-actions .btn {
    height: 100%;
}
.nav-link:not(.active) .icon  {
    color: #344767;
}
.nav-link.active .icon {
    color: #fff;
}
.icon-shape i {
    /* color: #fff; */
    opacity: 1 !important;
}
.icon-gem {
    color: #344767;
    font-size: 1rem !important;
}
.card img {
    max-width: 100%;
    margin: auto;
}
.m-show {
    display: none;
}
@media only screen and (max-width: 768px) {
    .products .card {
        height: auto;
    }
    .products .card-body .row.h-100 {
        flex-direction: column-reverse;
    }
    .m-show {
        display: block !important;
    }
    .m-hide {
        display: none !important;
    }
    .main-content >  .container-fluid {
        padding: 10px;
    }

    .navbar-nav.justify-content-end {
        justify-content: flex-start !important;
    }
    .keys_container .btn {
        white-space: nowrap;
    }
    .m-hide {
        display: none !important;
    }
    .btn {
        padding-left: .7rem;
        padding-right: .7rem;
    }
    .table-actions {
        flex-direction: column;
        max-height: fit-content !important;
        width: 100%;
        margin: 0;
    }
    .table-actions * {
        width: 100%;
        margin: 0 !important;
        height: auto !important;
        margin-bottom: .6rem !important;
        min-height: 33px;
    }
    .navbar-vertical.navbar-expand-xs .navbar-collapse {
        height: calc(100vh - 280px);
    }
}
.min-vh-30 {
    min-height: 220px;
}
.product-description {
    white-space: break-spaces;
}
.text-align-right {
    text-align: right !important;
}
.text-default {
    color: #344767 !important;
}
.space-between {
justify-content: space-between;
}
.flex-end {
    justify-content: flex-end;
}
.dop-btn {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.bolder {
    font-weight: bolder;
}
.sum_desc {
    margin-bottom: 0;
}
#hide_menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
}
.g-sidenav-pinned #hide_menu {
    pointer-events: all;
}

.alert.alert-danger {
  color: white;
  font-weight: bold;
}


/** feature/auto-payment */
.card-custom {
  border-radius: 15px;
  padding: 20px;
  max-width: 350px;
  font-family: 'Arial', sans-serif;
}
.card-visa{
  background: linear-gradient(135deg, #3B82F6, #F472B6);
  color: white;
}
.card-visa-business{
  background: #1a252f;
  color: white;
}

.card-visa-platinum{
  background: linear-gradient(135deg, #141415, #404040);
  color: white;
}
.card-visa-signature{
  background: linear-gradient(135deg, #8d8f92, #a6a7a9);
  color: white;
}

.card-visa-corporate{
  background: #1d3ace;
  color: white;
}
.card-mastercard{
  background: #fff;
  color: black;
}
.card-custom .card-balance {
  font-size: 1.2rem;
}
.card-custom .card-holder,
.card-custom .card-valid,
.card-custom .card-number {
  font-size: 1rem;
}
.card-number {
  background: rgba(255, 255, 255, 0.4);
  padding: 16px 20px;
  margin: -20px;
}

.card-custom .card-icon {
  height: 16px;
}

.card-item-title{
  font-size: 0.5rem;
  color: #ccc;
}

.modal-content {
  padding: 20px;
}
.payment-methods .card {
  margin-bottom: 10px;
}
.delivery-address, .total-price {
  margin-top: 20px;
}
.estimated-time {
  float: right;
}

.card-selected {
  border: 1px solid #ccc;
}

.deleting {
  opacity: 0.4;
}

.discount {

}

.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #3a4cfa;
  color: white;
  padding: 5px 10px;
}

.sidenav-footer {
  position: fixed;
  bottom: 16px;
}
